import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { CheckoutQuery } from '@nordic-web/gql'
import { type CheckoutConversionType, useCheckoutQuery } from '@nordic-web/gql'
import type { Package, PackageTier, ValidVoucher } from '@nordic-web/gql/src/types'
import type { PaymentMethodsResponse } from '@nordic-web/rest-codegen/generated/subscription'
import { useDidUpdateEffect } from '@nordic-web/utils/hooks/use-did-update-effect'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { stringFromQueryParam } from '@/utils/query-string'

export type CheckoutState = {
  hasAcceptedTerms: boolean
  setHasAcceptedTerms: (arg0: boolean) => void
  isCheckingOutWithBinding: boolean
  toggleBinding: (arg0: boolean) => void
  isAdFree: boolean
  setAdFree: (arg0: boolean) => void
  checkoutPackage?: Package
  selectedTier?: PackageTier
  resetCheckoutState: () => void
  conversionType?: CheckoutConversionType
  availablePaymentMethods?: PaymentMethodsResponse
  isWithAdsQuery?: string
  isWithBindingQuery?: string
  checkoutData?: CheckoutQuery
  isCheckoutLoading: boolean
  setVoucherCode: (voucherCode: string) => void
  resetVoucher: () => void
  validVoucherData?: ValidVoucher
  invalidVoucherReason?: string | null
}

export const CheckoutStateContext = createContext<CheckoutState | undefined>(undefined)

export const CheckoutStateProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isCheckingOutWithBinding, toggleBinding] = useState(false)
  const [isAdFree, setAdFree] = useState(false)
  const queryPackageId = stringFromQueryParam(router.query?.packageId) ?? ''
  const queryVoucherCode = stringFromQueryParam(router.query?.voucherCode) ?? ''
  const [voucherCode, setVoucherCode] = useState(queryVoucherCode)
  const [declineAutoApply, setDeclineAutoApply] = useState('')
  const { isLoggedIn } = useAuthenticationStore()
  const {
    data: currentCheckoutData,
    previousData: previousCheckoutData,
    loading: isCheckoutLoading,
    refetch: refetchCheckout,
  } = useCheckoutQuery({
    variables: { packageId: queryPackageId, voucherCode, declineAutoApply },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const checkoutData = currentCheckoutData ?? previousCheckoutData

  const selectedTier = useMemo(() => {
    if (!checkoutData?.checkout) return

    return checkoutData.checkout.package.tiers.find((tier) => tier.adFree === isAdFree)
  }, [checkoutData, isAdFree])

  const { withAds, withAdFree, withBinding } = router.query
  useEffect(() => {
    if (withAds) {
      setAdFree(!(withAds === 'true'))
    }
    if (withAdFree) {
      setAdFree(withAdFree === 'true')
    }
    if (withBinding) {
      toggleBinding(withBinding === 'true')
    }
  }, [withAds, withAdFree, withBinding])

  const resetCheckoutState = useCallback(() => {
    setHasAcceptedTerms(false)
    toggleBinding(false)
    setAdFree(false)
    setVoucherCode('')
  }, [])

  const availablePaymentMethods = checkoutData?.checkout?.availablePaymentMethods
    ? JSON.parse(checkoutData?.checkout?.availablePaymentMethods)
    : undefined

  useDidUpdateEffect(() => {
    if (isLoggedIn && checkoutData) {
      refetchCheckout()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, isLoggedIn)

  const validVoucherData = selectedTier?.voucher?.__typename === 'PackageTierValidVoucher' ? selectedTier.voucher : null
  const invalidVoucherReason =
    selectedTier?.voucher?.__typename === 'PackageTierInvalidVoucher' ? selectedTier.voucher.failureReason : null

  const handleSetVoucherCode = (voucherCode: string) => {
    setVoucherCode(voucherCode)

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, voucherCode },
      },
      undefined,
      { scroll: false }
    )
  }

  const resetVoucher = () => {
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, voucherCode: '' },
      },
      undefined,
      { scroll: false }
    )
    setVoucherCode('')
    setDeclineAutoApply(selectedTier?.id ?? '')
  }

  useEffect(() => {
    if (invalidVoucherReason) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, voucherCode: '' },
        },
        undefined,
        { scroll: false }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidVoucherReason])

  useEffect(() => {
    setDeclineAutoApply('')
  }, [queryPackageId])

  return (
    <CheckoutStateContext.Provider
      value={{
        hasAcceptedTerms,
        setHasAcceptedTerms,
        isCheckingOutWithBinding,
        toggleBinding,
        isAdFree,
        setAdFree,
        selectedTier,
        resetCheckoutState,
        checkoutPackage: checkoutData?.checkout?.package,
        conversionType: checkoutData?.checkout?.conversion,
        availablePaymentMethods,
        isWithAdsQuery: typeof withAds === 'string' ? withAds : undefined,
        isWithBindingQuery: typeof withBinding === 'string' ? withBinding : undefined,
        checkoutData,
        isCheckoutLoading,
        setVoucherCode: handleSetVoucherCode,
        resetVoucher,
        validVoucherData,
        invalidVoucherReason,
      }}
    >
      {children}
    </CheckoutStateContext.Provider>
  )
}

export const useCheckoutState = () => {
  const state = useContext(CheckoutStateContext)

  if (typeof state === 'undefined') {
    throw new Error('useCheckoutState must be used within a CheckoutStateContext')
  }

  return state
}
