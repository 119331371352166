import { PaymentMethodEnum } from '@/features/checkout/types'
import { nextConfig } from '@/helpers/env'
import { type TranslatedPaths, mtvPaths, tv4Paths } from '@/helpers/paths/translated-paths'

type AppLanguage = 'fi' | 'sv'
type ClientName = 'tv4-web' | 'mtv-web'
type FaqId = 'humany_tv4play' | 'humany_mtv-opas' | 'tv4-play' | 'tv4-play-fi'
type DataIntegrityPages = 'privacyPolicy' | 'userData' | 'termsOfUse' | 'cookiePolicy'

type AuthQueryParameters = {
  signUp: string
  logIn: string
  logOut: string
}

type AppDeepLinkConfig = {
  scheme: string
  appName: string
  appStoreId: string
  androidPackageName: string
  androidAppStoreUrl: string
  appleAppStoreUrl: string
}

type FaqConfig = {
  id: FaqId
  mediaLicense: string
  portability: string
}

const BRAND_FROM_ENV_VAR = nextConfig.string('BRAND')

export enum Brand {
  TV4 = 'tv4',
  MTV = 'mtv',
}

type BrandConfig = {
  language: AppLanguage
  domain: string
  clientName: ClientName
  hasChildLockEnabled: boolean
  hasMarketingSettings: boolean
  logo: string
  locale: string
  faq: FaqConfig
  translatedPaths: TranslatedPaths
  gdprLinks: Record<DataIntegrityPages, string>
  authQueryParameters: AuthQueryParameters
  deepLinkConfig: AppDeepLinkConfig
  currency: 'SEK' | 'EUR'
  availablePaymentMethods: PaymentMethodEnum | PaymentMethodEnum[]
  defaultPaymentMethod: PaymentMethodEnum
  retailName: string
  isSifoTrackingEnabled: boolean
  kindlyDataBotKey: string
}

const configs: Record<Brand, BrandConfig> = {
  [Brand.MTV]: {
    language: 'fi',
    domain: 'https://www.mtv.fi',
    clientName: 'mtv-web',
    hasChildLockEnabled: false,
    hasMarketingSettings: true,
    logo: '/static/mtv/mtv-logotype.svg',
    locale: 'fi_FI',
    faq: {
      id: 'tv4-play-fi',
      // TODO i18n
      mediaLicense: '',
      portability: '',
    },
    translatedPaths: mtvPaths,
    currency: 'EUR',
    gdprLinks: {
      privacyPolicy: 'tietosuojakaytantomme',
      userData: 'kayttajatietosi',
      termsOfUse: 'kayttoehdot',
      cookiePolicy: 'evastekaytanto',
    },
    authQueryParameters: {
      signUp: 'luo-kayttajatili',
      logIn: 'kirjaudu',
      logOut: 'kirjaudu-ulos',
    },
    deepLinkConfig: {
      scheme: 'x-mtvsuomi://',
      appName: 'MTV Katsomo',
      appStoreId: '778585708',
      androidPackageName: 'fi.mtvkatsomo',
      appleAppStoreUrl: 'https://apps.apple.com/fi/app/mtv-katsomo/id778585708',
      androidAppStoreUrl: 'https://play.google.com/store/apps/details?id=fi.mtvkatsomo',
    },
    availablePaymentMethods: [PaymentMethodEnum.CARD, PaymentMethodEnum.STORED_CARD],
    defaultPaymentMethod: PaymentMethodEnum.CARD,
    retailName: 'MTV OY',
    isSifoTrackingEnabled: false,
    kindlyDataBotKey: '9d67da18-7601-4b8e-8cc6-b4fe3a349dc4',
  },
  [Brand.TV4]: {
    language: 'sv',
    domain: 'https://www.tv4play.se',
    clientName: 'tv4-web',
    hasChildLockEnabled: true,
    hasMarketingSettings: false,
    logo: '/static/tv4/tv4play-logo.svg',
    locale: 'sv_SE',
    faq: {
      id: 'tv4-play',
      mediaLicense:
        'humany-tv4play=/8315/jag-har-problem-att-titta-pa-tv4play-i-webblasaren-microsoft-edge-vad-ska-jag-gora/',
      portability: 'humany-tv4play=/4080/detaljerad-information-om-att-anvanda-tv4-play-utomlands/;phrase:Detaljerad',
    },
    translatedPaths: tv4Paths,
    currency: 'SEK',
    gdprLinks: {
      privacyPolicy: 'personuppgiftspolicy',
      userData: 'din-anvandardata',
      termsOfUse: 'anvandarvillkor',
      cookiePolicy: 'cookiepolicy',
    },
    authQueryParameters: {
      signUp: 'skapa-konto',
      logIn: 'logga-in',
      logOut: 'logga-ut',
    },
    deepLinkConfig: {
      scheme: 'tv4play://',
      appName: 'TV4 Play',
      appStoreId: '413776071',
      androidPackageName: 'se.tv4.tv4playtab',
      appleAppStoreUrl: 'http://itunes.apple.com/se/app/tv4-play/id413776071?mt=8',
      androidAppStoreUrl: 'https://play.google.com/store/apps/details?id=se.tv4.tv4playtab',
    },
    availablePaymentMethods: [
      PaymentMethodEnum.CARD,
      PaymentMethodEnum.KLARNA_PAYNOW,
      PaymentMethodEnum.KLARNA_PAYLATER,
      PaymentMethodEnum.STORED_CARD,
    ],
    defaultPaymentMethod: PaymentMethodEnum.KLARNA_PAYNOW,
    retailName: 'TV4 AB',
    isSifoTrackingEnabled: true,
    kindlyDataBotKey: 'f94ac8c5-0d94-44c3-b899-84a1d7e34986',
  },
}

export const BRAND = BRAND_FROM_ENV_VAR === 'MTV' ? Brand.MTV : Brand.TV4
export const isTV4 = BRAND === Brand.TV4
export const isMTV = BRAND === Brand.MTV

export const brandConfig = configs[BRAND]
