import { useApolloClient } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { PaymentMethodsDocument } from '@nordic-web/gql'
import { ApiError, OpenAPI, type PaymentDetailsRequest } from '@nordic-web/rest-codegen/generated/subscription'
import * as SubscriptionService from '@nordic-web/rest-codegen/generated/subscription'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { authenticationStore } from '@/features/auth/authentication-store'
import { nextConfig } from '@/helpers/env'
import { stringFromQueryParam } from '@/utils/query-string'

OpenAPI.BASE = nextConfig.string('SUBSCRIPTION_API')

const onError = (error: ApiError) => {
  console.error(`subscription api error: ${error}`)

  Bugsnag.notify('We got an error from the subscription API', (event) => {
    event.severity = 'warning'
    event.addMetadata('details', {
      errorCode: error?.status,
    })
  })
}

export function withSubscriptionApiErrorCallback<T extends (...args: unknown[]) => unknown>(queryFn: T) {
  return (...args: Parameters<T>) => {
    try {
      return queryFn(...args) as ReturnType<T>
    } catch (error) {
      if (error instanceof ApiError) {
        onError(error)
      }
      throw error
    }
  }
}

export const subscriptionQueryKey = 'subscription'
export const voucherQueryKey = [subscriptionQueryKey, 'voucher']
export const packageKey = ['packages']

export const useSubscription = (entitlementId?: string) => {
  const router = useRouter()
  const apolloClient = useApolloClient()
  const packageId = entitlementId || (stringFromQueryParam(router.query?.packageId) ?? '')

  const zeroPaymentMutation = useMutation({
    mutationFn: async (data: Record<string, unknown>) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.addUserPaymentMethod({
        authorization: formatAuthorizationHeader(token),
        requestBody: {
          paymentMethod: data.paymentMethod,
          browserInfo: data.browserInfo,
          riskData: data.riskData,
          vimondId: packageId,
        },
      })
    },
  })

  const zeroPayment3DsMutation = useMutation({
    mutationFn: async (details: PaymentDetailsRequest) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.addPaymentMethodDetails({
        authorization: formatAuthorizationHeader(token),
        requestBody: details,
      })
    },
    onError,
  })

  const deletePaymentMethod = useMutation({
    mutationFn: async (paymentMethodId: string) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.removePaymentMethod({
        authorization: formatAuthorizationHeader(token),
        paymentMethodId,
      })
    },
    onError,
    onSuccess: () => {
      apolloClient.refetchQueries({ include: [PaymentMethodsDocument] })
    },
  })

  return {
    zeroPaymentMutation,
    zeroPayment3DsMutation,
    deletePaymentMethod,
  }
}
