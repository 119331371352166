import React from 'react'
import { usePanelQuery } from '@nordic-web/gql'
import { isTypeInUnion } from '@nordic-web/utils/typescript'
import type { SwipeModuleProps } from '@/components/swipe-module/swipe-module'
import { SwipeModule, getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'

type PaginatedSwipeModuleProps = Omit<SwipeModuleProps, 'items' | 'hasMoreItems'> & {
  panelId: string
}

export const PanelQuerySwipeModule = ({ panelId, ...rest }: PaginatedSwipeModuleProps) => {
  const { data, fetchMore } = usePanelQuery({
    ssr: false,
    fetchPolicy: 'cache-and-network',
    variables: {
      panelId,
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })
  const panel = data?.panel
  const isValidPanel = isTypeInUnion(
    panel,
    'ShortsPanel',
    'ClipsPanel',
    'EpisodesPanel',
    'MediaPanel',
    'ChannelPanel',
    'SportEventPanel',
    'PagePanel'
  )

  if (!isValidPanel) return

  const pageInfo = panel.content.pageInfo
  const items = panel.content.items.map((item) => (item.__typename === 'EpisodesPanelItem' ? item : item.content))
  const hasNextPage = !!pageInfo?.hasNextPage

  return (
    <SwipeModule
      showOnlyUpsellLabel={panel.__typename === 'MediaPanel' && panel.hideLabels}
      hasMoreItems={hasNextPage}
      fetchMoreData={() => {
        fetchMore({
          variables: {
            limit: getSwipeModuleApiLimit(),
            offset: pageInfo?.nextPageOffset,
          },
        })
      }}
      items={items}
      {...rest}
    />
  )
}
