import React, { useMemo } from 'react'
import { ChannelPanelType, MediaPanelCardDisplayRatio } from '@nordic-web/gql'
import { nonNullable } from '@nordic-web/utils/typescript'
import { LivePanel } from '@/components/live-panel'
import { denseBreakpoints, shortsBreakpoints } from '@/components/slider/slider-breakpoints'
import { PanelQuerySwipeModule } from '@/components/swipe-module/panel-query-swipe-module'
import { ThemePanel } from '@/components/theme-panel'
import { PanelTitle } from '@/components/typography/panel-title'
import { ChannelEpgPanel } from '@/features/channel-epg-panel'
import { ContinueWatchingPanel } from '@/features/continue-watching'
import { CompactPagePanel } from '@/features/panel-factory/components/compact-page-panel'
import { MultiSinglePanel } from '@/features/panel-factory/components/multi-single-panel'
import { PanelTitleLink } from '@/features/panel-factory/components/panel-title-link'
import { SinglePanel } from '@/features/panel-factory/components/single-panel'
import { paths } from '@/helpers/paths'
import { usePanelTracking } from '@/tracking/panel-tracking'
import type { PanelItem } from './types'

export const wantsFullWidth = (panel: PanelItem) => {
  switch (panel.__typename) {
    case 'SinglePanel':
    case 'MultiSinglePanel': {
      return true
    }
    case 'PagePanel': {
      return panel.isCompact
    }
    case 'ChannelPanel': {
      return panel.type === ChannelPanelType.Epg
    }
    default:
      return false
  }
}

type PanelFactoryProps = {
  panel: PanelItem
  panel_row_index: number
  cardQueryParameters: object
  hidePromoCdpButton?: boolean
}

export const PanelFactory = ({
  panel,
  panel_row_index,
  cardQueryParameters = {},
  hidePromoCdpButton = false,
}: PanelFactoryProps) => {
  const panelTracking = usePanelTracking()
  const content = useMemo(() => {
    switch (panel.__typename) {
      case 'MultiSinglePanel': {
        if (panel.singlePanels?.length === 0) return null

        if (panel.singlePanels?.length === 1 && panel.singlePanels[0]) {
          return (
            <SinglePanel hideCdpButton={false} key={panel.id} index={panel_row_index} panel={panel.singlePanels[0]} />
          )
        }

        return <MultiSinglePanel key={panel.id} panels={panel.singlePanels?.filter(nonNullable) ?? []} />
      }
      case 'PagePanel': {
        if (panel.isCompact) {
          return <CompactPagePanel key={panel.id} panelId={panel.id} />
        }

        return (
          <PanelQuerySwipeModule
            key={panel.id}
            panelId={panel.id}
            headerComponent={<PanelTitle>{panel.title}</PanelTitle>}
            breakpoints={denseBreakpoints}
            ratio="1 / 1"
          />
        )
      }
      case 'ShortsPanel': {
        return (
          <PanelQuerySwipeModule
            key={panel.id}
            panelId={panel.id}
            headerComponent={<PanelTitle>{panel.title}</PanelTitle>}
            breakpoints={shortsBreakpoints}
            ratio="2 / 3"
          />
        )
      }
      case 'ClipsPanel':
      case 'EpisodesPanel':
      case 'SportEventPanel': {
        return (
          <PanelQuerySwipeModule
            key={panel.id}
            panelId={panel.id}
            headerComponent={<PanelTitle>{panel.title}</PanelTitle>}
            cardQueryParameters={cardQueryParameters}
            ratio="16 / 9"
          />
        )
      }
      case 'MediaPanel': {
        const ratio =
          panel.displayHint.mediaPanelImageRatio === MediaPanelCardDisplayRatio.Portrait_2X3 ? '2 / 3' : '16 / 9'
        return (
          <PanelQuerySwipeModule
            key={panel.id}
            panelId={panel.id}
            headerComponent={
              <PanelTitleLink
                title={panel.title}
                href={paths.list.urlString({ id: panel.id, slug: panel.slug })}
                onClick={() => panelTracking?.trackOnPanelClick()}
              />
            }
            breakpoints={ratio === '2 / 3' ? denseBreakpoints : undefined}
            ratio={ratio}
          />
        )
      }
      case 'ChannelPanel': {
        if (panel.type === ChannelPanelType.Epg) {
          return <ChannelEpgPanel panelId={panel.id} />
        }

        return (
          <PanelQuerySwipeModule
            key={panel.id}
            panelId={panel.id}
            headerComponent={
              <PanelTitleLink
                title={panel.title}
                href={paths.channels.urlString()}
                onClick={() => panelTracking?.trackOnPanelClick()}
              />
            }
            breakpoints={denseBreakpoints}
            ratio="1 / 1"
          />
        )
      }
      case 'SinglePanel': {
        return <SinglePanel key={panel.id} hideCdpButton={hidePromoCdpButton} index={panel_row_index} panel={panel} />
      }
      case 'ThemePanel': {
        return <ThemePanel key={panel.id} panel={panel} />
      }
      case 'LivePanel': {
        return <LivePanel key={panel.id} panel={panel} index={panel_row_index} />
      }
      case 'ContinueWatchingPanel': {
        return <ContinueWatchingPanel key={panel.id} panel={panel} />
      }

      default:
        return null
    }
  }, [panel, panel_row_index, cardQueryParameters, hidePromoCdpButton, panelTracking])

  return content
}
