import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { forceHideWebviewBanners } from '@/styles/force-hide-webview-banners'
import { stringFromQueryParam } from '@/utils/query-string'

export const isWebviewRequest = (source?: string) =>
  source ? ['ios', 'android', 'samsung', 'onetrust'].includes(source) : false

export const useNativeWebviewStyling = () => {
  const router = useRouter()
  const [sourceParam] = useState(stringFromQueryParam(router.query.source))
  const isWebview = sourceParam && isWebviewRequest(sourceParam)

  useEffect(() => {
    if (isWebview) document.body.classList.add(forceHideWebviewBanners)
    return () => document.body.classList.remove(forceHideWebviewBanners)
  }, [isWebview])

  return isWebview
}
