import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { trackPanelClick, trackPanelImpression } from '@/features/search/tracking/snowplow/snowplow-core-tracking'
import { getPageName } from '@/helpers/get-page-name'
import { dataLayerPush } from '@/tracking/tracking'

type PanelTrackingContextProps = {
  panel_id?: string
  panel_row_index: number
  trackOnPanelLoadMore: () => void
  trackOnPanelClick: () => void
}

type PanelTrackingPropsProps = {
  panel_id?: string
  panel_row_index: number
}

const PanelTrackingContext = createContext<PanelTrackingContextProps | undefined>(undefined)

export const PanelTracking = ({ children, panel_row_index, panel_id }: PropsWithChildren<PanelTrackingPropsProps>) => {
  const inViewChanged = (inView: boolean) => {
    if (inView) {
      dataLayerPush({
        event: 'panel_impression',
        panel_id,
        panel_row_index,
        page_name: getPageName(),
      })
      trackPanelImpression({
        panel_id: panel_id || '',
        panel_row_index,
      })
    }
  }

  const trackOnPanelLoadMore = () => {
    dataLayerPush({
      event: 'panel_load_assets',
      panel_id,
      panel_row_index,
      page_name: getPageName(),
    })
  }

  const trackOnPanelClick = () => {
    dataLayerPush({
      event: 'panel_click',
      panel_id,
      panel_row_index,
      page_name: getPageName(),
    })
    trackPanelClick({
      panel_id: panel_id || '',
      panel_row_index,
    })
  }

  const value = useMemo(
    () => ({ panel_row_index, panel_id, trackOnPanelLoadMore, trackOnPanelClick }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panel_row_index, panel_id]
  )

  return (
    <PanelTrackingContext.Provider value={value}>
      <InView onChange={inViewChanged} style={{ width: '100%', height: '100%' }} triggerOnce>
        {children}
      </InView>
    </PanelTrackingContext.Provider>
  )
}

export const usePanelTracking = () => {
  return useContext(PanelTrackingContext)
}
